const accordionHeaders = document.querySelectorAll('.accordion-header');

accordionHeaders.forEach((header) => {
  header.addEventListener('click', () => {
    const accordionBody = header.nextElementSibling;
    const accordionSvg = header.querySelector('.accordion-span');
    console.log(accordionSvg);

    accordionBody.classList.toggle('h-0');
    accordionBody.classList.toggle('opacity-0');
    accordionBody.classList.toggle('py-8');
    accordionBody.classList.add('ease-in-out', 'duration-500');
    accordionSvg.classList.toggle('rotate-180');
    
  });
});